.App {
  text-align: center;
}

.error {
  color: #bf0000;
}

.success {
  color: #00af00;
}

.text-black-20 {
  color: rgba(0, 0, 0, 0.2) !important;
}
